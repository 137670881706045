import * as React from 'react';
import { DefaultButton, Dialog, DialogType, ProgressIndicator, Text } from '@fluentui/react';
import { t } from 'i18next';

interface IBasicBlockUI {
  blockUI: boolean;
}

const BasicBlockUI = (props: IBasicBlockUI) => {
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  const dialogContentProps = {
    type: DialogType.largeHeader,
    titleProps: { id: 'block-dialog-id' },
  };

  const block = React.useMemo(() => {
    return props.blockUI;
  }, [props.blockUI]);

  const [pendingFreeBlock, setPendingFreeBlock] = React.useState<number>(0);

  React.useEffect(() => {
    if (props.blockUI) {
      setPendingFreeBlock((prevPending) => prevPending + 1);
    } else {
      setPendingFreeBlock((prevPending) => (prevPending > 0 ? prevPending - 1 : 0));
    }
  }, [props.blockUI]);

  return (
    <>
      <Dialog hidden={pendingFreeBlock === 0} modalProps={modalProps} dialogContentProps={dialogContentProps}>
        <div className="text-center">
          <div>
            <Text variant="mediumPlus">Cargando</Text>
          </div>

          <div className="mt-2">
            <ProgressIndicator />
          </div>
          <div>
            <Text variant="medium" className="mt-2">
              Aguarda unos instantes
            </Text>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default BasicBlockUI;
