import { Pivot, PivotItem, Text, Icon, DefaultButton } from '@fluentui/react';
import { DateTime } from 'luxon';
import TorneoTabFixture from './TorneoTabFixture';
import TorneoTabGoleadores from './TorneoTabGoleadores';
import TorneoTabTablaPosiciones from './TorneoTabTablaPosiciones';
import { todosContraTodos, Torneo } from '../../system/SystemClient';
import { genkey, PARTIDOS_EXTRA_ZONA_NAME } from '../../common/constants';
import { ZonaFixture } from '../Common/ZonaFixture';
import { useNavigate } from 'react-router-dom';

interface ITorneoContainer {
  torneo: Torneo;
  hideBack?: boolean;
  hideRefresh?: boolean;
  setRefresh?: (v: boolean) => void;
  refresh?: boolean;
  lastUpdate?: Date;
  highlightClubId?: string;
}

export const TorneoContainer = ({
  torneo,
  hideBack,
  hideRefresh,
  setRefresh,
  refresh,
  lastUpdate,
  highlightClubId,
}: ITorneoContainer) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="ms-Grid mt-2" dir="ltr" style={{ marginBottom: 10 }}></div>
      <div className="ms-Grid-col ms-sm12">
        <div className="ms-Grid-row " style={{ display: 'flex' }}>
          <div className="ms-Grid-col ms-sm12 mb-5">
            <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
              <>
                <div className="ms-Grid-row padding-1">
                  <div className="ms-Grid-col ms-sm12 text-center"></div>
                  <div className="ms-Grid-col ms-md8 ms-sm12">
                    <Text className="nombre-header-torneo d-block">
                      {torneo.detalle.nombreTorneo} |{' '}
                      <span style={{ fontWeight: 400 }}>
                        {torneo.detalle.categoria.categoriaNombre} -{' '}
                        {torneo.detalle.division.divisionNombre
                          ? torneo.detalle.division.divisionNombre + ' - '
                          : ''}
                        {torneo.detalle.rama === 'M' ? 'Masculino ' : 'Femenino '}
                      </span>
                    </Text>
                    <Text variant="small" className="d-block">
                      {torneo.detalle.federacion.federacionNombre}
                    </Text>
                  </div>
                  <div className="ms-Grid-col ms-md4 ms-sm12">
                    <div className="ms-Grid-row mt-1">
                      {!hideBack && (
                        <div className="ms-Grid-col ms-sm12">
                          <DefaultButton className="float-right" onClick={() => navigate('/')}>
                            Volver al menú
                          </DefaultButton>
                        </div>
                      )}
                      {!hideRefresh && setRefresh && lastUpdate && (
                        <div className="ms-Grid-col ms-sm12 mt-1">
                          <Text
                            variant="small"
                            className="link-styles clickable float-right"
                            onClick={() => {
                              setRefresh(!refresh);
                            }}
                          >
                            Actualizado: {DateTime.fromJSDate(lastUpdate).toFormat('dd/LL/yyyy HH:mm')}
                            <Icon className="ml-1 " iconName="Refresh"></Icon>
                          </Text>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="ms-Grid-col ms-sm12"
                  style={{ borderTop: '1px solid rgb(232, 232, 232)' }}
                ></div>
                <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <div className="ms-Grid-row padding-row-custom">
                    <Pivot aria-label="" className="text-center customFocusZone">
                      <PivotItem
                        headerText="Fixture"
                        itemKey={'1'}
                        onRenderItemLink={(itemProps) => {
                          return (
                            <div>
                              <span>{itemProps?.headerText}</span>
                            </div>
                          );
                        }}
                      >
                        <TorneoTabFixture
                          torneo={torneo}
                          highlightClubId={highlightClubId}
                        ></TorneoTabFixture>
                      </PivotItem>
                      {torneo.detalle.muestraTabla &&
                        torneo.fases.filter((f) => todosContraTodos(f)).length > 0 && (
                          <PivotItem
                            headerText="Tabla posiciones"
                            itemKey={'2'}
                            onRenderItemLink={(itemProps) => {
                              return (
                                <div>
                                  <span>{itemProps?.headerText}</span>
                                </div>
                              );
                            }}
                          >
                            <Pivot aria-label="" className="text-left">
                              {torneo.fases
                                .filter((f) => todosContraTodos(f))
                                .map((fase, iFase) => {
                                  return (
                                    <PivotItem headerText={fase.nombre} key={genkey()}>
                                      {fase.zonas
                                        .filter((zona) => zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME)
                                        .map((zona) => {
                                          return (
                                            <div key={genkey()}>
                                              {fase.zonas.filter(
                                                (zona) => zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME
                                              ).length > 1 && <ZonaFixture zona={zona} />}
                                              <TorneoTabTablaPosiciones
                                                tablaPosiciones={zona.tabla}
                                                tablaGeneralPosiciones={zona.tablaGeneral}
                                                highlightClubId={highlightClubId}
                                              ></TorneoTabTablaPosiciones>
                                            </div>
                                          );
                                        })}
                                    </PivotItem>
                                  );
                                })}
                            </Pivot>
                          </PivotItem>
                        )}
                      {torneo.detalle.muestraGoleadores && (
                        <PivotItem
                          headerText="Goleadores"
                          itemKey={'3'}
                          onRenderItemLink={(itemProps) => {
                            return (
                              <div>
                                <span>{itemProps?.headerText}</span>
                              </div>
                            );
                          }}
                        >
                          <TorneoTabGoleadores
                            goleadores={torneo.goleadores}
                            highlightClubId={highlightClubId}
                          ></TorneoTabGoleadores>
                        </PivotItem>
                      )}
                    </Pivot>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
