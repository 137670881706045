import React from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode, Text } from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { TablaPosiciones } from '../../system/SystemClient';
import { PartidoFixture } from '../Common/PartidoFixture';
import { genkey } from '../../common/constants';
import { ZonaFixture } from '../Common/ZonaFixture';
import { TorneoLlaves } from './TorneoLlaves';

const TorneoTabTablaPosiciones = ({
  tablaPosiciones,
  tablaGeneralPosiciones,
  highlightClubId,
}: {
  tablaPosiciones: TablaPosiciones[];
  tablaGeneralPosiciones: TablaPosiciones[];
  highlightClubId?: string;
}) => {
  const getCols = (compact?: boolean) => {
    const columnsDesktop = [
      {
        key: 'posicion',
        name: '#',
        fieldName: 'posicion',
        minWidth: compact ? 10 : 70,
        maxWidth: compact ? 10 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return <span style={{ fontSize: compact ? 12 : 16 }}>{item.puesto}</span>;
        },
      },
      {
        key: 'club',
        name: 'Club',
        fieldName: 'club',
        minWidth: compact ? 90 : 400,
        maxWidth: compact ? 90 : 400,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <div>
              {compact ? (
                <>
                  <div style={{ display: 'inline-block' }}>
                    <ClubImageCircle escudo={item.club_escudo} width={19} height={19}></ClubImageCircle>
                  </div>

                  <span
                    style={{
                      fontSize: 12,
                      marginTop: 0,
                      display: 'inline-block',
                      position: 'relative',
                      bottom: 6,
                      left: 3,
                      fontWeight: !!highlightClubId && highlightClubId === item.clubId ? 500 : 400,
                      color: !!highlightClubId && highlightClubId === item.clubId ? 'red' : '#605e5c',
                    }}
                  >
                    {item.club}
                  </span>
                </>
              ) : (
                <>
                  <div style={{ display: 'inline-block' }}>
                    <ClubImageCircle escudo={item.club_escudo} width={30} height={30}></ClubImageCircle>
                  </div>

                  <span
                    style={{
                      fontSize: 16,
                      marginTop: 0,
                      display: 'inline-block',
                      position: 'relative',
                      bottom: 10,
                      left: 10,
                      fontWeight: !!highlightClubId && highlightClubId === item.clubId ? 500 : 400,
                      color: !!highlightClubId && highlightClubId === item.clubId ? 'red' : '#605e5c',
                    }}
                  >
                    {item.club}
                  </span>
                </>
              )}
            </div>
          );
        },
      },
      {
        key: 'PJ',
        name: 'PJ',
        fieldName: 'PJ',
        minWidth: compact ? 20 : 70,
        maxWidth: compact ? 20 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <span className="text-center" style={{ fontSize: compact ? 12 : 16 }}>
              {item.partidosJugados}
            </span>
          );
        },
      },

      {
        key: 'pg',
        name: 'PG',
        fieldName: 'pg',
        minWidth: compact ? 20 : 70,
        maxWidth: compact ? 20 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <span className="text-center" style={{ fontSize: compact ? 12 : 16 }}>
              {item.partidosGanados}
            </span>
          );
        },
      },

      {
        key: 'PE',
        name: 'PE',
        fieldName: 'PE',
        minWidth: compact ? 20 : 70,
        maxWidth: compact ? 20 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return <span style={{ fontSize: compact ? 12 : 16 }}>{item.partidosEmpatados}</span>;
        },
      },

      {
        key: 'PP',
        name: 'PP',
        fieldName: 'PP',
        minWidth: compact ? 20 : 70,
        maxWidth: compact ? 20 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return <span style={{ fontSize: compact ? 12 : 16 }}>{item.partidosPerdidos}</span>;
        },
      },

      {
        key: 'GF',
        name: 'GF',
        fieldName: 'GF',
        minWidth: compact ? 20 : 70,
        maxWidth: compact ? 20 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return <span style={{ fontSize: compact ? 12 : 16 }}>{item.golesAFavor}</span>;
        },
      },

      {
        key: 'GC',
        name: 'GC',
        fieldName: 'GC',
        minWidth: compact ? 20 : 70,
        maxWidth: compact ? 20 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return <span style={{ fontSize: compact ? 12 : 16 }}>{item.golesEnContra}</span>;
        },
      },
      {
        key: 'DF',
        name: 'DF',
        fieldName: 'DF',
        minWidth: compact ? 20 : 70,
        maxWidth: compact ? 20 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return <span style={{ fontSize: compact ? 12 : 16 }}>{item.golesAFavor - item.golesEnContra}</span>;
        },
      },

      {
        key: 'PTS',
        name: 'PTS',
        fieldName: 'PTS',
        minWidth: compact ? 25 : 70,
        maxWidth: compact ? 25 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return <span style={{ fontSize: compact ? 12 : 16, fontWeight: 700 }}>{item.puntos}</span>;
        },
      },
      {
        key: 'tieneQuitaPuntos',
        name: '',
        fieldName: 'tieneQuitaPuntos',
        minWidth: compact ? 20 : 70,
        maxWidth: compact ? 20 : 70,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <span
              className="error-color blinking-icon"
              style={{ fontSize: compact ? 12 : 16, fontWeight: 500 }}
            >
              {item.tieneQuitaPuntos ? '(*) Con descuento de puntos' : ''}
            </span>
          );
        },
      },
    ];

    if (compact) {
      const aux = columnsDesktop[6];
      columnsDesktop[6] = columnsDesktop[9];
      columnsDesktop[9] = aux;
    }
    return columnsDesktop;
  };

  return (
    <>
      {tablaPosiciones.length > 0 ? (
        <>
          {/* =====================================================================
                              VISTA DESKTOP
        =====================================================================*/}
          <div id="viewDesktop" className="ms-hiddenSm">
            <div className="ms-Grid-row ">
              <div className="ms-Grid-col ms-sm12 text-center">
                <DetailsList
                  className="no-scroll"
                  onShouldVirtualize={() => false}
                  items={tablaPosiciones}
                  columns={getCols()}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                />
              </div>
            </div>
            {tablaGeneralPosiciones.length > 0 && (
              <div className="ms-Grid-row mt-5">
                <div className="ms-Grid-col ms-sm12 text-center">
                  <Text variant="mediumPlus" style={{ fontWeight: 500 }}>
                    Tabla general de posiciones
                  </Text>
                </div>

                <div className="ms-Grid-col ms-sm12 text-center">
                  <DetailsList
                    className="no-scroll"
                    onShouldVirtualize={() => false}
                    items={tablaGeneralPosiciones}
                    columns={getCols()}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                  />
                </div>
              </div>
            )}
          </div>
          {/* =====================================================================
                              VISTA MOBILE
        =====================================================================*/}

          <div id="viewMobile" className="ms-hiddenMdUp">
            <div className="ms-Grid-row ">
              <div className="ms-Grid-col ms-sm12 text-center">
                <DetailsList
                  className=""
                  onShouldVirtualize={() => false}
                  items={tablaPosiciones}
                  columns={getCols(true)}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                />
              </div>
            </div>

            {tablaGeneralPosiciones.length > 0 && (
              <div className="ms-Grid-row mt-5">
                <div className="ms-Grid-col ms-sm12 text-center">
                  <Text variant="mediumPlus" style={{ fontWeight: 500 }}>
                    Tabla general de posiciones
                  </Text>
                </div>
                <div className="ms-Grid-col ms-sm12 text-center">
                  <DetailsList
                    className=""
                    onShouldVirtualize={() => false}
                    items={tablaGeneralPosiciones}
                    columns={getCols(true)}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="ms-Grid-col ms-sm12 mt-2 text-center">
          <Text variant="medium">Todavía no hay tabla de posiciones para este torneo.</Text>
        </div>
      )}
    </>
  );
};

export default TorneoTabTablaPosiciones;
