import { IConfigLayout } from '../components/Common/Layout/Base';
import Environment from '../lib/Environment';

export const USA_PUBLICIDAD = false;
export interface AdImages {
  imagePath: string;
  redirectTo: string;
}

export const loadConfig = async () => {
  const environment = new Environment();

  const subdirectorio = environment.getLocalBasePath();
  const data = (await (await fetch(`${subdirectorio}/config-layout.json`)).json()) as IConfigLayout;
  return data;
};

// export const adImages: AdImages[] = [
//   { imagePath: './images/logo-bna.png', redirectTo: 'https://www.bna.com.ar/Personas' },
//   { imagePath: './images/logo-go7.png', redirectTo: 'https://www.go7.com.ar/' },
//   { imagePath: './images/logo-cah.png', redirectTo: 'https://handballargentina.org/cah/' },
// ];

// export const headerImages: AdImages[] = [
//   {
//     imagePath: 'https://www.femebal.com/images/logo/Logo-Femebal-Nuevo-2022-footer.png',
//     redirectTo: 'https://www.femebal.com/',
//   },
// ];

// export const headerColor = '#c7e0f4';
// // export const headerColor = '#ffcaa9'; // naranja

// // export const imageBackground = '';
// export const imageBackground =
//   'https://handballargentina.org/cah/wp-content/uploads/2022/10/Estadio-4-web.jpg';

// export const LOGO_HEADER_PATH = './logo-tecdata.png';
