import React from 'react';
import { Pivot, PivotItem, Text, Icon, DefaultButton } from '@fluentui/react';
import { DateTime } from 'luxon';
import TorneoTabFixture from './TorneoTabFixture';
import TorneoTabGoleadores from './TorneoTabGoleadores';
import TorneoTabTablaPosiciones from './TorneoTabTablaPosiciones';
import { ModeloGrilla, todosContraTodos, Torneo } from '../../system/SystemClient';
import { genkey, PARTIDOS_EXTRA_ZONA_NAME, unhash } from '../../common/constants';
import { ZonaFixture } from '../Common/ZonaFixture';
import { TorneoNotFound } from '../Common/TorneoNotFound';
import {
  AppContext,
  CRITERIO_CATEGORIA,
  CRITERIO_FEDERACION,
  CRITERIO_RAMA,
  CRITERIO_TEMPORADA,
  MenuFilters,
} from '../../AppInRouterContext';
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  IStackItemStyles,
  Dropdown,
  IDropdownOption,
  DropdownMenuItemType,
} from '@fluentui/react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TorneoContainer } from './TorneoContainer';
interface ITorneoDetailViewProps {
  appContext: AppContext;
  onChangeFilters: (criterio: string, value: string) => void;
  menuFilters: MenuFilters;

  categorias: IDropdownOption<any>[];
  temporadas: IDropdownOption<any>[];
  federaciones: IDropdownOption<any>[];
  ramas: IDropdownOption<any>[];
}

const TorneoDetailView = (props: ITorneoDetailViewProps) => {
  let { id } = useParams();

  const [searchParams] = useSearchParams();
  const byLink = searchParams.get('direct'); // ejemplo: ?myParam=valor

  const [torneo, setTorneo] = React.useState<Torneo | undefined>(undefined);
  const [lastUpdate, setLastUpdate] = React.useState<any>(new Date());
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [showTorneoNotFoundDialog, setShowTorneoNotFoundDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getTorneo = async () => {
      try {
        if (id) {
          let password = props.appContext.system.getEnvironment().passphrase();

          const idUnhashed = unhash(id, password);

          props.appContext.changeBlockUI(true);
          const ret = await props.appContext.system.getTorneo(idUnhashed);
          if (ret !== null) {
            setTorneo(ret);
            setLastUpdate(new Date());
          } else {
            setShowTorneoNotFoundDialog(true);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        props.appContext.changeBlockUI(false);
      }
    };
    getTorneo();

    const algunPartidoEnVivo = torneo?.fases.some((fase) =>
      fase.zonas.some((zona) => zona.partidos.some((partido) => partido.playing))
    );

    let intervaloTiempo = 300000; // 5 minutos

    if (algunPartidoEnVivo) {
      intervaloTiempo = 60000; // 1 minuto
    }

    const intervalo = setInterval(getTorneo, intervaloTiempo);

    return () => clearInterval(intervalo);
  }, [refresh]);

  const navigate = useNavigate();

  return (
    <>
      {torneo && (
        <TorneoContainer
          torneo={torneo}
          hideBack={!!byLink}
          lastUpdate={lastUpdate}
          refresh={refresh}
          setRefresh={setRefresh}
          hideRefresh={false}
        ></TorneoContainer>
      )}
      <TorneoNotFound
        showTorneoNotFoundDialog={showTorneoNotFoundDialog}
        setShowTorneoNotFoundDialog={setShowTorneoNotFoundDialog}
      ></TorneoNotFound>
    </>
  );
};

export default TorneoDetailView;
