import React from 'react';
import {
  Pivot,
  PivotItem,
  Text,
  IconButton,
  Icon,
  DefaultButton,
  ChoiceGroup,
  IChoiceGroupOption,
  Dropdown,
} from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { grilla, ModeloGrilla, Partido, todosContraTodos, Torneo, Zona } from '../../system/SystemClient';
import { PartidoFixture } from '../Common/PartidoFixture';
import { genkey, PARTIDOS_EXTRA_ZONA_NAME } from '../../common/constants';
import { FechaFixture, ZonaFixture } from '../Common/ZonaFixture';
import { TorneoLlaves } from './TorneoLlaves';
import { InstanciaPorFase, TorneoInstanciaFixture } from './TorneoInstanciaFixture';
import { DateTime } from 'luxon';

const FIXTURE = 'F';
const LLAVES = 'L';

const TorneoTabFixture = ({ torneo, highlightClubId }: { torneo: Torneo; highlightClubId?: string }) => {
  const [modeView, setModeView] = React.useState<string>(FIXTURE);
  const [filterMatchDay, setFilterMatchDay] = React.useState<string>('');
  const [currentFaseId, setCurrentFaseId] = React.useState<string>(torneo.fases[0].id);
  const obtenerFechaPorNumeroFecha = (partidos: Partido[], numeroFecha: number) => {
    // Filtramos el array para encontrar el partido correspondiente con el numeroFecha
    const partido = partidos.find((item) => item.numeroFecha === numeroFecha.toString());

    // Extraemos el atributo "horario" del partido
    const fechaHorario = new Date(partido!.horario);
    fechaHorario.setHours(0, 0, 0, 0);
    return fechaHorario;
  };

  const possiblesMatchDaysByFase = React.useMemo(() => {
    const ret = torneo.fases.map((f) => {
      const partidos = f.zonas[0].partidos;
      let numerosFechas = partidos.map((partido) => partido.numeroFecha);

      numerosFechas = numerosFechas.filter(function (item, pos) {
        return numerosFechas.indexOf(item) === pos;
      });

      let fechas = numerosFechas.map((fecha) => {
        return {
          numeroFecha: fecha,
          fechaUsual: obtenerFechaPorNumeroFecha(partidos, +fecha),
        };
      });

      const res = { faseId: f.id, fechas: fechas };
      console.log('Fechas', res);
      return res;
    });
    return ret;
  }, []);

  React.useEffect(() => {
    const posiblesFechas = possiblesMatchDaysByFase.find((p) => p.faseId === currentFaseId);
    const fase = torneo.fases.find((f) => f.id === currentFaseId);

    if (posiblesFechas && fase && todosContraTodos(fase)) {
      const hoy = DateTime.now().startOf('day'); // Fecha de hoy sin horas
      const diaSemana = hoy.weekday; // 1 = Lunes, ..., 7 = Domingo
      const esJuevesViernes = diaSemana === 4 || diaSemana === 5;

      let fechaObj;

      // Convertimos las fechas a objetos DateTime y las ordenamos cronológicamente
      const fechasOrdenadas = posiblesFechas.fechas
        .sort((a, b) => a.fechaUsual.getTime() - b.fechaUsual.getTime())

        .map((item) => ({
          ...item,
          date: DateTime.fromJSDate(item.fechaUsual).startOf('day'),
        }));
      // Si hoy es antes de la primera fecha, tomamos la primera
      if (hoy < fechasOrdenadas[0].date) {
        fechaObj = fechasOrdenadas[0];
      } else if (esJuevesViernes) {
        // Si es jueves o viernes, buscamos la próxima fecha futura
        fechaObj = fechasOrdenadas.find((item) => item.date > hoy);
      } else {
        // Si es miércoles o antes, buscamos la fecha más reciente sin pasarnos
        fechaObj =
          fechasOrdenadas.find((item) => item.date.equals(hoy)) ||
          fechasOrdenadas.reverse().find((item) => item.date <= hoy);
      }

      // Si encontramos la fecha, la seteamos
      if (fechaObj) {
        setFilterMatchDay(fechaObj.numeroFecha);
      } else {
        setFilterMatchDay(''); // Si no hay ninguna fecha válida, limpiamos el estado
      }
    }
  }, [possiblesMatchDaysByFase, currentFaseId]);

  const instanciasPorFase: InstanciaPorFase[] = React.useMemo(() => {
    return torneo.fases.map((fase) => {
      let instancias: any[] = [];
      fase.modeloGrilla.forEach((modelo) => {
        const existe = instancias.find(
          (i) =>
            i.ronda === modelo.mtg_ronda &&
            i.descripcion === modelo.mtg_descripcion &&
            i.llave_principal === modelo.mtg_llave_principal
        );
        if (!existe) {
          instancias.push({
            ronda: modelo.mtg_ronda,
            descripcion: modelo.mtg_descripcion,
            llave_principal: modelo.mtg_llave_principal,
            partidos: [modelo.mtg_partido_numero],
          });
        } else {
          existe.partidos.push(modelo.mtg_partido_numero);
        }
      });

      return {
        fase: fase.nombre,
        instancias: instancias,
      };
    });
  }, []);

  const zonaHasClubHighlight = (zona: Zona) => {
    if (highlightClubId) {
      return zona.partidos.some(
        (p) => p.idClubLocal === highlightClubId || p.idClubVisitante === highlightClubId
      );
    } else {
      return true;
    }
  };

  const verTodasLasFechas = React.useMemo(() => {
    return filterMatchDay === '';
  }, [filterMatchDay]);

  return (
    <>
      <Pivot aria-label="" className="text-left">
        {torneo.fases.map((fase, iFase) => (
          <PivotItem headerText={fase.nombre} key={genkey()} onClick={() => setCurrentFaseId(fase.id)}>
            {grilla(fase) && (
              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12">
                  <DefaultButton
                    onClick={() => setModeView(FIXTURE)}
                    disabled={modeView === FIXTURE}
                    iconProps={{ iconName: 'Calendar' }}
                  >
                    Fixture
                  </DefaultButton>
                  <DefaultButton
                    style={{ marginLeft: 5 }}
                    onClick={() => setModeView(LLAVES)}
                    disabled={modeView === LLAVES}
                    iconProps={{ iconName: 'Trophy2' }}
                  >
                    Llaves
                  </DefaultButton>
                </div>
              </div>
            )}

            {todosContraTodos(fase) && (
              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12">
                  <div className="ms-hiddenSm" style={{ display: 'inline-block' }}>
                    {possiblesMatchDaysByFase
                      .find((f) => f.faseId === fase.id)
                      ?.fechas.map((fecha) => {
                        return (
                          <DefaultButton
                            onClick={() => setFilterMatchDay(String(fecha.numeroFecha))}
                            primary={filterMatchDay === fecha.numeroFecha}
                            style={{ marginRight: 5, minWidth: 50, width: 50, minHeight: 50, height: 50 }}
                            key={genkey()}
                          >
                            {fecha.numeroFecha}
                          </DefaultButton>
                        );
                      })}
                  </div>{' '}
                  <DefaultButton
                    onClick={() => {
                      setFilterMatchDay('');
                    }}
                    style={{ marginRight: 5, height: 50 }}
                    primary={verTodasLasFechas}
                  >
                    Todas las fechas
                  </DefaultButton>
                  <div
                    className="ms-hiddenMdUp"
                    style={{ display: 'inline-block', width: '50%', float: 'right' }}
                  >
                    <Dropdown
                      onChange={(e, opt) => {
                        setFilterMatchDay(String(opt!.key!));
                      }}
                      selectedKey={filterMatchDay}
                      placeholder="Fecha especifica"
                      options={
                        possiblesMatchDaysByFase
                          .find((f) => f.faseId === fase.id)
                          ?.fechas.map((fecha) => {
                            return { key: fecha.numeroFecha, text: 'Fecha ' + fecha.numeroFecha };
                          })!
                      }
                    ></Dropdown>
                  </div>
                </div>
              </div>
            )}

            {todosContraTodos(fase) ? (
              <div>
                {fase.zonas
                  .filter((zona) => zonaHasClubHighlight(zona))
                  .map((zona, iZona) => {
                    return (
                      <div key={genkey()}>
                        {zona.partidos.length > 0 && (
                          <div className="mt-2">
                            {fase.zonas.filter((zona) => zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME).length >
                              1 && <ZonaFixture zona={zona} />}
                            {filterMatchDay === '' &&
                              possiblesMatchDaysByFase
                                .find((fxf) => fxf.faseId === fase.id)!
                                .fechas.map((fecha) => {
                                  return (
                                    <>
                                      {zona.partidos
                                        .filter((p) => p.numeroFecha === String(fecha.numeroFecha))
                                        .filter((p) =>
                                          !!highlightClubId
                                            ? p.idClubLocal === highlightClubId ||
                                              p.idClubVisitante === highlightClubId
                                            : true
                                        ).length > 0 && (
                                        <>
                                          <FechaFixture fecha={String(fecha.numeroFecha)}></FechaFixture>
                                          {zona.partidos
                                            .filter((p) => p.numeroFecha === String(fecha.numeroFecha))
                                            .filter((p) =>
                                              !!highlightClubId
                                                ? p.idClubLocal === highlightClubId ||
                                                  p.idClubVisitante === highlightClubId
                                                : true
                                            )
                                            .map((partido, iPartido) => {
                                              return (
                                                <PartidoFixture
                                                  partido={partido}
                                                  highlightClubId={highlightClubId}
                                                />
                                              );
                                            })}
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                            {!verTodasLasFechas &&
                              zona.partidos
                                .filter((p) => (!verTodasLasFechas ? p.numeroFecha === filterMatchDay : true))
                                .map((partido, iPartido) => {
                                  return (
                                    <PartidoFixture partido={partido} highlightClubId={highlightClubId} />
                                  );
                                })}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>
                {fase.zonas.map((zona, iZona) => {
                  return (
                    <div key={genkey()}>
                      {fase.zonas.filter((zona) => zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME).length > 1 && (
                        <ZonaFixture zona={zona} />
                      )}
                      {zona.partidos.length > 0 && (
                        <div className="mt-2">
                          {modeView === FIXTURE ? (
                            <TorneoInstanciaFixture
                              zona={zona}
                              fase={fase}
                              instanciasPorFase={instanciasPorFase}
                              highlightClubId={highlightClubId}
                            ></TorneoInstanciaFixture>
                          ) : (
                            <div>
                              {zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME && (
                                <TorneoLlaves zona={zona} modeloGrilla={fase.modeloGrilla} />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </PivotItem>
        ))}
      </Pivot>
    </>
  );
};

export default TorneoTabFixture;
