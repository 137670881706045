class Environment {
  apiUrl(path: string) {
    // return `${this.base}${path}`;
    return this.retrieveRequired('REACT_APP_REMOTE_API_ENDPOINT') + path;
  }

  getLocalBasePath(): string {
    return this.retrieveRequired('REACT_APP_LOCAL_BASE_PATH');
  }

  private retrieveRequired(key: string) {
    const value = process.env[key];
    if (value === undefined) {
      throw new Error(`${key} is not defined`);
    }
    return value;
  }

  passphrase(): string {
    return this.retrieveRequired('REACT_APP_PASSPHRASE');
  }

  federacionDefault(): string {
    return this.retrieveRequired('REACT_APP_FEDERACION_ID');
  }

  footerAdvertising(): string {
    // return this.retrieveRequired('REACT_APP_FOOTER_ADVERTISING');
    return "0";
  }
}

export default Environment;
