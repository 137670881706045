import React from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SystemClient, { ContextMenu, Federacion, Rama } from './system/SystemClient';
import Environment from './lib/Environment';
import MessageCourier from './lib/MessageCourier';
import BasicBlockUI from './components/Common/BasicBlockUI';
import XhrRequestError from './lib/XhrRequestError';
import XhrErrorListener from './lib/XhrErrorListener';
import { NotFound } from './components/NotFound';
import Base from './components/Common/Layout/Base';
import TorneoDetailView from './components/Torneo/TorneoView';
import ResumenTorneos from './components/Torneo/ResumenTorneos';
import { IDropdownOption } from '@fluentui/react';

export interface AppContext {
  system: SystemClient;
  courier: MessageCourier;
  changeBlockUI: (v: boolean) => void;
  noAdvertising: string | null
}

interface IRouterContextProps {
  system: SystemClient;
  environment: Environment;
  courier: MessageCourier;
}

class ErrorListener implements XhrErrorListener {
  constructor(private onAuthenticationError: (error: XhrRequestError) => void) {}

  receiveError(error: XhrRequestError): void {
    console.log('error :>> **', error);
    if (error.isAuthenticationRequiredError()) {
      this.onAuthenticationError(error);
    }
  }
}

export interface MenuFilters {
  temporadaId: string;
  federacionId: string;
  categoriaId: string;
  rama: string;
}

export const calcularTotalTorneosPorFederacion = (federacion: Federacion) => {
  return federacion.ramas.reduce((total, rama) => {
    return (
      total +
      rama.categorias.reduce((subtotal, categoria) => {
        return subtotal + +categoria.cantTorneos;
      }, 0)
    );
  }, 0);
};

export const calcularTotalTorneosPorRama = (rama: Rama) => {
  return rama.categorias.reduce((total, categoria) => {
    return total + parseInt(categoria.cantTorneos, 10);
  }, 0);
};

const getLegendCantTorneos = (cantTorneos: number) =>
  cantTorneos === 0 ? '(Sin torneos)' : cantTorneos === 1 ? '(1 torneo)' : `(${cantTorneos} torneos)`;

export const CRITERIO_TEMPORADA = 'T';
export const CRITERIO_FEDERACION = 'F';
export const CRITERIO_CATEGORIA = 'C';
export const CRITERIO_RAMA = 'R';

const AppInRouterContext = (props: IRouterContextProps) => {
  const [block, setBlock] = React.useState(false);

  const changeBlockUI = (shouldBlock: boolean) => {
    setBlock(shouldBlock);
  };

  const [searchParams] = useSearchParams();
  const noAdvertising = searchParams.get('noAdv'); // ejemplo: ?myParam=valor


  const appContext: AppContext = {
    system: props.system,
    changeBlockUI: changeBlockUI,
    courier: props.courier,
    noAdvertising: noAdvertising
  };

  const [contextMenu, setContextMenu] = React.useState<ContextMenu[] | undefined>(undefined);
  const [categorias, setCategorias] = React.useState<IDropdownOption<any>[]>([]);
  const [temporadas, setTemporadas] = React.useState<IDropdownOption<any>[]>([]);
  const [federaciones, setFederaciones] = React.useState<IDropdownOption<any>[]>([]);
  const [ramas, setRamas] = React.useState<IDropdownOption<any>[]>([]);

  const setOptionsByTemporada = (temporada: ContextMenu) => {
    setMenuFilters({ ...menuFilters, temporadaId: temporada.id });
    const conTorneos = temporada.federaciones.filter((fed) => calcularTotalTorneosPorFederacion(fed) !== 0); // Filtra todos los que no son 0
    const sinTorneos = temporada.federaciones.filter((fed) => calcularTotalTorneosPorFederacion(fed) === 0); // Filtra solo los ceros
    const federaciones = conTorneos.concat(sinTorneos); // Combina ambos arrays, primero los que no son 0, luego los ceros

    setFederaciones(
      federaciones.map((f) => {
        const cantTorneos = calcularTotalTorneosPorFederacion(f);
        return {
          key: f.id,
          text: `${f.nombre} `,
          data: { cantTorneos: +cantTorneos },
          disabled: +cantTorneos === 0,
          title: +cantTorneos === 0 ? 'Sin torneos' : '',
        };
      })
    );
  };

  React.useEffect(() => {
    const getContext = async () => {
      try {
        changeBlockUI(true);
        const ret = await props.system.getContextMenu();
        setContextMenu(ret);
        const temporadaActual = ret.find((t) => t.actual);

        setTemporadas(
          ret.map((t) => {
            return { key: t.id, text: t.descripcion };
          })
        );

        if (temporadaActual) {
          setOptionsByTemporada(temporadaActual);
        }
      } catch (error) {
        console.log(error);
      } finally {
        changeBlockUI(false);
      }
    };
    getContext();
  }, [props.system]);

  const [menuFilters, setMenuFilters] = React.useState<MenuFilters>({
    temporadaId: '',
    federacionId: props.environment.federacionDefault() ? props.environment.federacionDefault() : '',
    categoriaId: '',
    rama: '',
  });

  React.useEffect(() => {
    if (menuFilters.federacionId) {
      const fed = contextMenu
        ?.find((c) => c.id == menuFilters.temporadaId)
        ?.federaciones.find((f) => f.id === menuFilters.federacionId);
      if (fed) {
        const conTorneos = fed.ramas.filter((ramas) => calcularTotalTorneosPorRama(ramas) !== 0); // Filtra todos los que no son 0
        const sinTorneos = fed.ramas.filter((ramas) => calcularTotalTorneosPorRama(ramas) === 0); // Filtra solo los ceros
        const ramas = conTorneos.concat(sinTorneos); // Combina ambos arrays, primero los que no son 0, luego los ceros

        setRamas(
          ramas.map((r) => {
            const cantTorneos = calcularTotalTorneosPorRama(r);
            return {
              key: r.key,
              text: `${r.text} `,
              data: { cantTorneos: +cantTorneos },
              disabled: +cantTorneos === 0,
              title: +cantTorneos === 0 ? 'Sin torneos' : '',
            };
          })
        );
      }
      const rama = contextMenu
        ?.find((c) => c.id == menuFilters.temporadaId)
        ?.federaciones.find((f) => f.id === menuFilters.federacionId)
        ?.ramas.find((r) => r.key === menuFilters.rama);
      if (rama) {
        const conTorneos = rama.categorias.filter((cat) => +cat.cantTorneos !== 0); // Filtra todos los que no son 0
        const sinTorneos = rama.categorias.filter((cat) => +cat.cantTorneos === 0); // Filtra solo los ceros
        const categorias = conTorneos.concat(sinTorneos); // Combina ambos arrays, primero los que no son 0, luego los ceros

        setCategorias(
          categorias.map((c) => {
            return {
              key: c.id,
              text: `${c.nombre} ${getLegendCantTorneos(+c.cantTorneos)}`,
              data: { cantTorneos: +c.cantTorneos },
              disabled: +c.cantTorneos === 0,
              title: +c.cantTorneos === 0 ? 'Sin torneos' : '',
            };
          })
        );
      }
    }
  }, [menuFilters]);

  const onChangeFilters = (criterio: string, value: string) => {
    switch (criterio) {
      case CRITERIO_TEMPORADA:
        if (value !== menuFilters.temporadaId) {
          const t = contextMenu?.find((t) => t.id === value);
          if (t) setOptionsByTemporada(t);
          setMenuFilters({ temporadaId: value, federacionId: '', categoriaId: '', rama: '' });
        }
        break;
      case CRITERIO_FEDERACION:
        if (value !== menuFilters.federacionId) {
          setMenuFilters({ ...menuFilters, federacionId: value });
        }
        break;
      case CRITERIO_CATEGORIA:
        if (value !== menuFilters.categoriaId) {
          setMenuFilters({ ...menuFilters, categoriaId: value });
        }
        break;
      case CRITERIO_RAMA:
        if (value !== menuFilters.rama) {
          setMenuFilters({ ...menuFilters, rama: value });
        }
        break;
    }
  };

  return (
    <>
      <BasicBlockUI blockUI={block} />
      <Routes>
        <Route path="/" element={<Base appContext={appContext} />}>
          <Route
            path=":id"
            element={
              <TorneoDetailView
                categorias={categorias}
                temporadas={temporadas}
                federaciones={federaciones}
                ramas={ramas}
                onChangeFilters={onChangeFilters}
                menuFilters={menuFilters}
                appContext={appContext}
              />
            }
          />
          <Route
            path=""
            element={
              <ResumenTorneos
                categorias={categorias}
                temporadas={temporadas}
                federaciones={federaciones}
                ramas={ramas}
                onChangeFilters={onChangeFilters}
                appContext={appContext}
                menuFilters={menuFilters}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppInRouterContext;
