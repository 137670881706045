import React from 'react';

import { Image, ImageFit } from '@fluentui/react/lib/Image';
import Environment from '../../lib/Environment';

interface IJugadorImageCircle {
  escudo?: string;
  width: number;
  height: number;
  useMarginForGrid?: boolean;
}

export const JugadorImageCircle = (props: IJugadorImageCircle) => {
  const environment = new Environment();
  return (
    <Image
      src={props.escudo ? props.escudo : `${environment.getLocalBasePath()}/images/persona_default.jpg`}
      imageFit={ImageFit.cover}
      maximizeFrame
      styles={{
        root: {
          borderRadius: '50%',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          width: `${props.width}px`,
          height: `${props.height}px`,
          marginLeft: props.useMarginForGrid ? 10 : 0,
        },
      }}
    />
  );
};

export default JugadorImageCircle;
