import React from 'react';
import {
  Pivot,
  PivotItem,
  Text,
  IconButton,
  Icon,
  DefaultButton,
  ChoiceGroup,
  IChoiceGroupOption,
} from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { grilla, ModeloGrilla, Zona, Fase } from '../../system/SystemClient';
import { PartidoFixture } from '../Common/PartidoFixture';
import { genkey, PARTIDOS_EXTRA_ZONA_NAME } from '../../common/constants';
import { ZonaFixture } from '../Common/ZonaFixture';
import { TorneoLlaves } from './TorneoLlaves';

export interface Instancia {
  ronda: string;
  descripcion: string;
  llave_principal: string;
  partidos: string[];
}

export interface InstanciaPorFase {
  fase: string;
  instancias: Instancia[];
}

export const TorneoInstanciaFixture = ({
  zona,
  fase,
  instanciasPorFase,
  highlightClubId,
}: {
  zona: Zona;
  fase: Fase;
  instanciasPorFase: InstanciaPorFase[];
  highlightClubId?: string;
}) => {
  return (
    <div>
      {instanciasPorFase
        .find((ixf) => ixf.fase === fase.nombre)
        ?.instancias.map((instancia, instanciaIdx) => {
          return (
            <div>
              {zona.partidos
                .filter((p, idxP) => instancia.partidos.some((ip) => +ip === idxP + 1))
                .filter((p) =>
                  !!highlightClubId
                    ? p.idClubLocal === highlightClubId || p.idClubVisitante === highlightClubId
                    : true
                ).length > 0 && (
                <div className="ms-Grid-col ms-sm12 ">
                  <div className="ms-Grid-row" style={{ marginTop: 15, marginBottom: 15 }}>
                    <div className="ms-Grid-col ms-sm12 instancia-fecha">
                      <div className="ms-Grid-row" style={{ marginTop: 10, marginBottom: 10 }}>
                        <div className="ms-Grid-col ms-sm12">
                          <Text variant="large" className="fecha-label">
                            {instancia.llave_principal && (
                              <Icon
                                title="Llave principal"
                                className="mr-1"
                                style={{ fontWeight: 600 }}
                                iconName="Trophy2"
                              ></Icon>
                            )}
                            <Text
                              className="fecha-label"
                              variant={instancia.llave_principal ? 'large' : 'medium'}
                              style={{ fontWeight: 500 }}
                            >
                              {zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME
                                ? `${instancia.descripcion}`
                                : 'Partidos extras'}
                            </Text>
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  {zona.partidos
                    .filter((p, idxP) => instancia.partidos.some((ip) => +ip === idxP + 1))
                    .filter((p) =>
                      !!highlightClubId
                        ? p.idClubLocal === highlightClubId || p.idClubVisitante === highlightClubId
                        : true
                    )
                    .map((partido, iPartido) => {
                      return <PartidoFixture partido={partido} highlightClubId={highlightClubId} />;
                    })}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};
